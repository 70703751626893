*{
    box-sizing: border-box;
    font-family: Inter, sans-serif;
}
body{
    margin: 0px;
    background-color: #23252C;
    color: white;
}

.app-container{
    border-radius: 10px;
    margin: 20px auto;
    padding: 20px 10px;
    width: 517px;
    height:1000px;
}

.app-container--two{
    border-radius: 10px;
    margin: 20px auto;
    background-color: #1A1B21;
    /* border: 1px solid black; */
    width: 317px;
    height: 727px;
}


/*about section */
.header > img{
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
    width: 317px;
    height: 280px;
}

.h1-name{
    font-size: 27px;
    text-align: center;
    margin: 15px 0px 5px 0px;
}

.h3-role{
    font-size: 15px;
    text-align: center;
    margin-top: 0px;
    color: #F3BF99;
    font-weight: 300;
    margin-bottom:10px;
}

.btn-class{
    display: flex;
    padding: 20px 10px;
    justify-content: space-between;
}

.main-btn{
    width: 115px;
    height: 35px;
    border-radius: 6px;
    display: flex;
    border: none;
    outline: none;
    align-items: center;
}

.btn-email{
    background-color: white;
    color: black;
    display: flex;
    font-size: 16px;
    margin-left: 22px;
    padding: 5px 20px;
}

.btn-linkdin{
    background-color: #5093E2;
    color: white;
    display: flex;
    font-size: 16px;
    margin-right: 22px;
    padding: 5px 12px;
}

.email{
    height: 20px;
    margin: 4px;
}

.linkdin{
    height: 20px;
    margin: 4px;
}

.main--about--interest{
    width: 247px;
    height: 246px;
    padding: 10px;
    padding-bottom: 4px;
    margin: 5px auto;
    overflow: hidden;
    font-size: 12px;
    line-height: 1.08em;
}

.main--about--interest > p{
    font-size: 11px;
    text-align: justify;
    word-spacing: .05em;
}

.main--about--interest >h3{
    margin-bottom: 3px;
    margin-top: 4px;
}

.footer{
    width: 317px;
    height: 64px;
    background-color: #161619;
    padding: 3px;
    align-items: center;
    vertical-align: middle;
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px;
}

.footer-image{
    height: 30px;
    filter: brightness(2);
    margin: 10px;
}

.footer-items{
    margin: 2px 100px;
}